<template>
  <div class="team-center">
    <div class="team-list" v-for="(item, index) of list" :key="index">
      <div class="team-img">
        <img :src="require('../../assets/avatar/Avatar-' + index + '.png')" />
      </div>
      <div class="team-name">
        <span :class="{ h5Style: $store.state.isH5 }">{{ item.name }}</span>
      </div>
      <div class="team-tip">
        <span :class="{ h5Style: $store.state.isH5 }">{{ item.tip }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          name: '曹戈博',
          tip: '董事总经理',
        },
        {
          name: '丁飞洋',
          tip: '董事总经理，市场品牌负责人',
        },
        {
          name: '冯馨',
          tip: '董事总经理',
        },
        {
          name: '富鑫',
          tip: '合伙人',
        },
        {
          name: '公元',
          tip: '董事总经理',
        },
        {
          name: '顾翠萍',
          tip: '董事总经理',
        },
        {
          name: '郭山汕',
          tip: '合伙人',
        },
        {
          name: '郭振炜',
          tip: '董事总经理',
        },
        {
          name: '汉江',
          tip: '董事总经理',
        },
        {
          name: '何田田',
          tip: '董事总经理',
        },
        {
          name: '胡若笛',
          tip: '合伙人',
        },
        {
          name: '计越',
          tip: '合伙人',
        },
        {
          name: '贾沁',
          tip: '合伙人，并购业务',
        },
        {
          name: '李俊峰',
          tip: '投资合伙人，红杉碳中和研究院院长',
        },
        {
          name: '刘天羽',
          tip: '董事总经理，基础设施投资',
        },
        {
          name: '刘星',
          tip: '合伙人',
        },
        {
          name: '缪海源',
          tip: '合伙人，人力资本负责人',
        },
        {
          name: '浦晓燕',
          tip: '合伙人',
        },
        {
          name: '钱骥',
          tip: '董事总经理',
        },
        {
          name: '沈南鹏',
          tip: '红杉中国创始及执行合伙人',
        },
        {
          name: '苏凯',
          tip: '合伙人',
        },
        {
          name: '孙谦',
          tip: '合伙人',
        },
        {
          name: '王昊',
          tip: '董事总经理',
        },
        {
          name: '王恺',
          tip: '董事总经理',
        },
        {
          name: '王临青',
          tip: '董事总经理，投资运营负责人',
        },
        {
          name: '吴海兵',
          tip: '投资合伙人',
        },
        {
          name: '吴茗',
          tip: '投资合伙人',
        },
        {
          name: '项晓骁',
          tip: '董事总经理',
        },
        {
          name: '徐强',
          tip: '董事总经理',
        },
        {
          name: '徐青',
          tip: '合伙人，基础设施投资',
        },
        {
          name: '杨云霞',
          tip: '合伙人',
        },
        {
          name: '杨兆国',
          tip: '投资合伙人',
        },
        {
          name: '叶小慧',
          tip: '合伙人及首席财务官',
        },
        {
          name: '于瀚清',
          tip: '董事总经理',
        },
        {
          name: '翟佳',
          tip: '董事总经理',
        },
        {
          name: '张岸元',
          tip: '董事总经理，首席经济学家',
        },
        {
          name: '张涵',
          tip: '合伙人',
        },
        {
          name: '张联庆',
          tip: '合伙人及总法律顾问',
        },
        {
          name: '张宇',
          tip: '投资合伙人',
        },
        {
          name: '郑庆生',
          tip: '合伙人',
        },
        {
          name: '周逵',
          tip: '合伙人',
        },
        {
          name: '邹家佳',
          tip: '合伙人',
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.team-center {
  // width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 95px;

  .team-list {
    margin-bottom: 39px;
    cursor: pointer;
  }

  .team-img {
    width: 500px;
    height: 381px;
    flex-shrink: 0;
    background: linear-gradient(to top, #8e8d8a, #a3a29f, #c9c8c5);
    // background: lightgray 1.914px -5.944px / 100% 131.25% no-repeat;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .team-name {
    color: var(--Colors-Basic-Color-3, #fff);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    /* Headline/02 */
    font-family: 'Noto Sans S Chinese';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px; /* 141.667% */
    letter-spacing: -0.8px;
    margin-top: 27px;
  }

  .team-tip {
    color: var(--Colors-Basic-Color-4, #d4d4d4);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    /* Headline/04 */
    font-family: 'Noto Sans S Chinese';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 155.556% */
    letter-spacing: -0.4px;
    margin-top: 9px;
  }
}
</style>
