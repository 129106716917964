<template>
  <div class="enterprise-center">
    <div class="title">行业<img src="../../assets/down.png" /></div>
    <div class="content">
      <div class="content-false content-first">
        <div>
          <span :class="{ h5Style: $store.state.isH5 }">Company Name</span>
        </div>
        <div>
          <span :class="{ h5Style: $store.state.isH5 }">Short Description</span>
        </div>
        <div><span :class="{ h5Style: $store.state.isH5 }">行业</span></div>
      </div>
    </div>
    <div class="content" v-for="item of list" :key="item.id">
      <img
        class="show"
        src="../../assets/show.png"
        :class="{ contentShowTrue: contentShow }"
        @click="imgClick(item)"
      />
      <!-- <span
        class="show"
        :class="{ contentShowTrue: contentShow }"
        @click="contentShow = true"
        >+</span
      > -->
      <div v-if="!item.status" class="content-false content-list">
        <div>
          <span>{{ item.name }}</span>
        </div>
        <div>
          <span :class="{ h5EnterpriseStyle: $store.state.isH5 }">{{
            item.tip
          }}</span>
        </div>
        <div>
          <span :class="{ h5Style: $store.state.isH5 }">{{ item.type }}</span>
        </div>
      </div>
      <div v-else class="content-true" v-html="content"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contentShow: false,
      list: [
        {
          id: 1,
          name: 'AMI Paris',
          tip: 'AMI于2011年诞生于巴黎，从品牌创建伊始就不断地从这座城市获得灵感。AMI在法语中意为“朋友”，代表着一种轻松、真实、友好的奢侈时尚理念。品牌致力于为男士和女士提供时尚和全面的衣橱选择，打破时髦与休闲的边界。AMI创始人和创意总监Alexandre Mattiussi曾获ANDAM大奖',
          type: '消费',
          status: false,
        },
        {
          id: 2,
          name: 'Beam Therapeutics',
          tip: 'Beam Therapeutics是一家美国精确基因药物研发商。',
          type: '医疗',
          status: false,
        },
        {
          id: 3,
          name: 'Boss直聘',
          tip: 'BOSS直聘成立于2014年，是目前国内平均月度活跃用户数（MAU）最大的在线招聘平台。BOSS直聘率先引入了一种改变中国在线招聘业的颠覆性模式——直聘模式，促进了boss和求职者之间的即时直接聊天，为双方提供精准的推荐，并由强大的人工智能算法和大数据洞察提供支持。',
          type: '科技',
          status: false,
        },
        {
          id: 4,
          name: 'Canva',
          tip: 'Canva是全球最大的在线平面设计平台，首创拖拽式设计工具，2013年诞生于澳大利亚。秉承“赋予全世界设计的力量”的使命，至今Canva已拥有全球1亿用户，平均每秒有20个设计在Canva上诞生。',
          type: '科技',
          status: false,
        },
        {
          id: 5,
          name: 'Delivery Hero',
          tip: 'Delivery Hero 是一家德国跨国在线食品订购和食品配送公司',
          type: '科技',
          status: false,
        },
      ],
      content: `<section class="company">
                  <div class="content content--padded-top container">
                    <div class="l-two-col">
                      <div class="l-two-col__col">
                        <div class="u-d-flex u-flex-column u-gy-5">
                          <img src="https://www.hongshan.com/wp-content/uploads/sites/9/2022/11/84-AMI.png" class="company__logo-image" alt="AMI Paris" />
                          <div class="wysiwyg wysiwyg--fs-lg">
                            <p>AMI于2011年诞生于巴黎，从品牌创建伊始就不断地从这座城市获得灵感。AMI在法语中意为“朋友”，代表着一种轻松、真实、友好的奢侈时尚理念。品牌致力于为男士和女士提供时尚和全面的衣橱选择，打破时髦与休闲的边界。AMI创始人和创意总监Alexandre
                              Mattiussi曾获ANDAM大奖。</p>
                          </div>

                          <div class="l-hr-row">
                            <div class="l-pillbox l-hr-row__item">
                              <a href="http://www.amiparis.com/" class="button button--outline-light button--small"
                                target="_blank">
                                amiparis.com </a>
                              <section class="social-sharing__options u-mt-0">
                              </section>
                            </div>


                            <div class="l-pillbox l-hr-row__item">
                              <a data-bs-toggle="facet" data-bs-target="categories" data-bs-value="consumer"
                                href="https://www.hongshan.com/companies/?_categories=consumer"
                                class="pill pill--facet pill--active pill--passive">
                                消费 </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="l-two-col__col">
                        <div class="u-d-flex u-flex-column u-gy-5">

                          <div class="cgrid">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>`,
    };
  },
  methods: {
    imgClick(e) {
      // this.contentShow = true;
      e.status = !e.status;
    },
  },
};
</script>

<style lang="less" scoped>
@import url('./enterprise-center.less');
</style>
