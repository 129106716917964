import { createStore } from 'vuex';

export default createStore({
  state() {
    return {
      count: 0,
      isH5: false,
    };
  },
});
