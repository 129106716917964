<template>
  <div class="trends-center">
    <div class="trends-tabs">
      <div
        v-for="item of tabsList"
        :key="item.id"
        :class="{ trendsTabsStyle: tabIndex === item.id }"
        @click="tabClick(item)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="trends-content">
      <div class="trends-content-card" v-for="item of list" :key="item.id">
        <img :src="require('../../assets/img/trends' + item.id + '.png')" />
        <div>
          <span :class="{ h5Style: $store.state.isH5 }">{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabIndex: 1,
      tabsList: [
        {
          id: 1,
          name: '全部',
        },
        {
          id: 2,
          name: '观点',
        },
        {
          id: 3,
          name: '新闻',
        },
        {
          id: 4,
          name: '故事',
        },
      ],
      list: [
        {
          id: 1,
          name: 'Sparkle 02官宣报名｜ 消费早期创业者查收来信！',
        },
        {
          id: 2,
          name: '红杉中国发布《2023企业数字化指南》',
        },
        {
          id: 3,
          name: '专访元化智能孟庆虎：翻遍机器人领域的每一块石头',
        },
        {
          id: 4,
          name: '【报名中】欢迎来到YUÈ—— 红杉中国创业加速器',
        },
        {
          id: 5,
          name: '来小红书打开生活的万种想象',
        },
        {
          id: 6,
          name: '我们在灼灼火花中看见…… |「Sparkle」活动实录',
        },
      ],
    };
  },
  methods: {
    tabClick(e) {
      this.tabIndex = e.id;
    },
  },
};
</script>

<style lang="less" scoped>
.trends-tabs {
  width: 100%;
  display: flex;
  justify-content: center;

  div {
    width: 164px;
    height: 31px;
    line-height: 30px;
    margin: 0 25px 92px 25px;
    color: var(--Colors-Basic-Color-3, #fff);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    /* Headline/01 */
    font-family: 'Noto Sans S Chinese';
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -1.6px;
    cursor: pointer;
  }
}

.trendsTabsStyle {
  background: url('../../assets/trends-tabs-img.png') no-repeat 0 0/ 100% 100%;
}

.trends-content {
  width: calc(100% - 70px);
  padding: 0 35px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .trends-content-card {
    width: 390px;
    margin-bottom: 60px;
    cursor: pointer;

    img {
      width: 390px;
      height: 390px;
    }

    div {
      margin-top: 16px;
      color: var(--Colors-Basic-Color-3, #fff);
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      /* Headline/04 */
      font-family: 'Noto Sans S Chinese';
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 155.556% */
      letter-spacing: -0.4px;
    }
  }
}
</style>
