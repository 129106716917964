<template>
  <swiper
    class="view-banner"
    ref="swiper"
    :loop="true"
    :slides-per-view="3"
    :space-between="20"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
    <swiper-slide class="view-banner-list" v-for="item in list" :key="item.id">
      <div class="view-banner-card">
        <img :src="item.img" />
      </div>
      <div class="content">
        <div>{{ item.name }}</div>
        <div>{{ item.tip }}</div>
      </div>
    </swiper-slide>
    <div class="view-banner-content left" @click="addPage(-1)">
      <img src="../../assets/left.png" />
    </div>
    <div class="view-banner-content right" @click="addPage(1)">
      <img src="../../assets/right.png" />
    </div>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      useSwiper: null,
      list: [
        {
          id: 1,
          img: require('../../assets/img/gd1.png'),
          url: '11111',
          name: '数据1',
          tip: '数据1',
        },
        {
          id: 2,
          img: require('../../assets/img/gd2.png'),
          url: '222222222222222222222222222222222222222222222222222222',
          name: '专访元化智能孟庆虎：',
          tip: '翻遍机器人领域的每一块石头',
        },
        {
          id: 3,
          img: require('../../assets/img/gd3.png'),
          url: '33333',
          name: '数据3',
          tip: '数据3',
        },
      ],
    };
  },
  setup() {
    const onSlideChange = (e) => {
      console.log(e);
    };
    return {
      onSlideChange,
    };
  },
  methods: {
    onSwiper(swiper) {
      this.useSwiper = swiper;
    },
    addPage(num) {
      if (this.list.length === 3) this.list = this.list.concat(this.list);
      setTimeout(() => {
        if (num > 0) {
          this.useSwiper.slideNext();
        } else if (num < 0) {
          this.useSwiper.slidePrev();
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
@import url('./view-banner.less');
</style>
