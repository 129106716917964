<template>
  <div class="box">
    <indexHeader />
    <indexCenter />
    <indexBottom />
  </div>
</template>

<script>
import indexHeader from './indexHeader/index-header.vue';
import indexCenter from './indecCenter/index-center.vue';
import indexBottom from '@/app/indexBottom/index-bottom';
export default {
  // name: 'IndexProject',
  components: {
    indexHeader,
    indexCenter,
    indexBottom,
  },
};
</script>

<style scoped lang="less">
.box {
  background: #dfdfdf;
  overflow: hidden;
}
</style>
