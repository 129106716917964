<template>
  <div class="index-bottom" :class="{ innerStyle: inner }">
    <div class="index-bottom-title" :class="{ h5Style: $store.state.isH5 }">
      About
    </div>
    <div
      class="index-bottom-list"
      v-for="(item, index) of list"
      :key="index"
      @click="listClick(item)"
    >
      <span :class="{ h5Style: $store.state.isH5 }">{{ item.name }}</span>
    </div>
    <div class="index-bottom-icon">
      <img
        :class="{ h5Style: $store.state.isH5 }"
        v-for="item of iconList"
        :key="item.id"
        :src="
          require(`../../assets/index-icon${item.id}${
            inner ? '-inner' : ''
          }.png`)
        "
      />
    </div>
    <div class="index-bottom-line"></div>
    <div class="index-bottom-tip" :class="{ h5Style: $store.state.isH5 }">
      © 2024 Hongshan. All rights reserved.
    </div>
  </div>
</template>

<script>
export default {
  props: {
    inner: {
      typeof: Boolean,
      default: null,
    },
  },
  data() {
    return {
      list: [
        {
          name: '关于红杉',
          url: '/about',
        },
        {
          name: '联系方式',
          url: '/contact',
        },
        {
          name: '工作机会',
          url: '',
        },
        {
          name: '法律',
          url: '',
        },
      ],
      iconList: [
        {
          id: 1,
          url: '',
        },
        {
          id: 2,
          url: '',
        },
        {
          id: 3,
          url: '',
        },
        {
          id: 4,
          url: '',
        },
      ],
    };
  },
  methods: {
    listClick(e) {
      this.$router.push(e.url);
    },
  },
};
</script>

<style scoped lang="less">
@import url('./index-bottom.less');
</style>
