<template>
  <!-- <router-view></router-view> -->
  <transition name="fade" mode="out-in">
    <router-view />
  </transition>
</template>

<script>
export default {
  created() {
    const size = window.innerWidth >= 1290 ? 1 : window.innerWidth / 1290;
    document.getElementsByTagName('html')[0].style.transform = `scale(${size})`;
    // document.getElementsByTagName(
    //   'html'
    // )[0].style.height = `${window.innerHeight}px`;
    if (window.innerWidth < window.innerHeight) {
      this.$store.state.isH5 = true;
      document.getElementsByTagName('html')[0].style.background = `#014132`;
    }
  },
};
</script>

<style lang="less">
html {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

body {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 1290px;
  margin: auto;
  margin: 0;
  padding: 0;
  // background: #014132;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0.9;
}

//移动端适配
.h5Style {
  zoom: 1.5;
}

.h5LogoStyle {
  margin-top: 10px !important;
  zoom: 2;
}

.h5HeadStyle {
  zoom: 2;
}

.h5EnterpriseStyle {
  zoom: 1.5;
  line-height: 14px !important;
}
</style>
