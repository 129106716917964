<template>
  <div class="header">
    <search :searchClose="searchClose" v-if="searchShow" />
    <img
      v-if="inner"
      class="logo"
      :class="{ h5LogoStyle: $store.state.isH5 }"
      src="../../assets/logo-white.png"
      @click="back()"
    />
    <img
      v-else
      class="logo"
      :class="{ h5LogoStyle: $store.state.isH5 }"
      src="../../assets/logo.png"
      @click="back()"
    />
    <div class="tabs">
      <div
        v-for="item of tabs"
        :key="item.id"
        :class="{
          h5HeadStyle: $store.state.isH5,
          tabsStyle: $store.state.count === item.id,
        }"
        @click="tabsClick(item)"
      >
        {{ item.name }}
      </div>
      <div
        :class="{ h5HeadStyle: $store.state.isH5 }"
        @click="searchShow = true"
      >
        <img src="../../assets/search.png" />
      </div>
    </div>
  </div>
</template>

<script>
import search from '@/components/search/search';
export default {
  components: {
    search,
  },
  props: {
    inner: {
      typeof: Boolean,
      default: null,
    },
  },
  data() {
    return {
      searchShow: false,
      tabs: [
        {
          id: 1,
          name: '发展动态',
          url: '/trends',
        },
        {
          id: 2,
          name: '红杉团队',
          url: '/team',
        },
        {
          id: 3,
          name: '被投企业',
          url: '/enterprise',
        },
        // {
        //   id: 4,
        //   name: '中文',
        // },
      ],
    };
  },
  methods: {
    tabsClick(e) {
      this.$store.state.count = e.id;
      this.$router.push(e.url);
    },
    back() {
      this.$store.state.count = 0;
      this.$router.push('/');
    },
    searchClose() {
      this.searchShow = false;
    },
  },
};
</script>

<style scoped lang="less">
.header {
  width: 100%;
  height: 100px;

  .logo {
    width: 112px;
    height: 53px;
    margin: 20px 0 0 15px;
    cursor: pointer;
  }

  .tabs {
    color: white;
    display: flex;
    float: right;
    margin: 40px 50px 0 0;

    div {
      color: var(--Colors-Basic-Color-3, #fff);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: 'Noto Sans S Chinese';
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: -0.3px;
      margin-right: 13px;
      cursor: pointer;

      img {
        position: relative;
        top: 2px;
        width: 17px;
        height: 17px;
        margin-left: 10px;
      }
    }
  }

  .tabsStyle {
    background: url(../../assets/mark.png) no-repeat 0 10px/ 100% auto;
  }
}
</style>
