<template>
  <div class="index-center">
    <div class="index-center-title">
      <span :class="{ h5Style: $store.state.isH5 }">观点</span>
    </div>
    <viewBanner />
    <div class="index-center-title">
      <span :class="{ h5Style: $store.state.isH5 }">新闻</span>
    </div>
    <newsBanner :bannerList="newsList" />
    <div class="index-center-title">
      <span :class="{ h5Style: $store.state.isH5 }">故事</span>
    </div>
    <newsBanner :bannerList="storyList" />
  </div>
</template>

<script>
import viewBanner from '@/components/viewBanner/view-banner';
import newsBanner from '@/components/newsBanner/news-banner';
export default {
  components: {
    viewBanner,
    newsBanner,
  },
  data() {
    return {
      newsList: [
        {
          id: 1,
          img: require('../../assets/img/news1.png'),
          url: '11111',
          name: '点击报名 | 红杉中国创业加速器YUÈ',
        },
        {
          id: 2,
          three: true,
          children: [
            {
              id: 4,
              img: require('../../assets/img/news2.png'),
              url: '4444444',
              name: '关于我们',
            },
            {
              id: 5,
              img: require('../../assets/img/news3.png'),
              url: '555555',
              name: '红杉中国发布《2023企业数字化指南》',
              tip: '555555',
            },
            {
              id: 6,
              img: require('../../assets/img/news4.png'),
              url: '66666666',
              name: '红杉播客',
            },
          ],
        },
        {
          id: 5,
          children: [
            {
              id: 4,
              img: require('../../assets/img/news2.png'),
              url: '4444444',
              name: '关于我们',
            },
            {
              id: 5,
              img: require('../../assets/img/news3.png'),
              url: '555555',
              name: '红杉中国发布《2023企业数字化指南》',
              tip: '555555',
            },
            {
              id: 6,
              img: require('../../assets/img/news4.png'),
              url: '66666666',
              name: '红杉播客',
            },
            {
              id: 6,
              img: require('../../assets/img/news4.png'),
              url: '66666666',
              name: '红杉播客',
            },
          ],
        },
        {
          id: 3,
          children: [
            {
              id: 4,
              img: require('../../assets/img/news2.png'),
              url: '4444444',
              name: '关于我们',
            },
            {
              id: 5,
              img: require('../../assets/img/news3.png'),
              url: '555555',
              name: '红杉中国发布《2023企业数字化指南》',
              tip: '555555',
            },
            {
              id: 6,
              img: require('../../assets/img/news4.png'),
              url: '66666666',
              name: '红杉播客',
            },
            {
              id: 6,
              img: require('../../assets/img/news4.png'),
              url: '66666666',
              name: '红杉播客',
            },
          ],
        },
      ],
      storyList: [
        {
          id: 2,
          three: true,
          children: [
            {
              id: 4,
              img: require('../../assets/img/news2.png'),
              url: '4444444',
              name: '关于我们',
            },
            {
              id: 5,
              img: require('../../assets/img/news3.png'),
              url: '555555',
              name: '红杉中国发布《2023企业数字化指南》',
              tip: '555555',
            },
            {
              id: 6,
              img: require('../../assets/img/news4.png'),
              url: '66666666',
              name: '红杉播客',
            },
          ],
        },
        {
          id: 1,
          img: require('../../assets/img/news1.png'),
          url: '11111',
          name: '点击报名 | 红杉中国创业加速器YUÈ',
        },
        {
          id: 3,
          children: [
            {
              id: 4,
              img: require('../../assets/img/news2.png'),
              url: '4444444',
              name: '关于我们',
            },
            {
              id: 5,
              img: require('../../assets/img/news3.png'),
              url: '555555',
              name: '红杉中国发布《2023企业数字化指南》',
              tip: '555555',
            },
            {
              id: 6,
              img: require('../../assets/img/news4.png'),
              url: '66666666',
              name: '红杉播客',
            },
            {
              id: 6,
              img: require('../../assets/img/news4.png'),
              url: '66666666',
              name: '红杉播客',
            },
          ],
        },
        {
          id: 5,
          children: [
            {
              id: 4,
              img: require('../../assets/img/news2.png'),
              url: '4444444',
              name: '关于我们',
            },
            {
              id: 5,
              img: require('../../assets/img/news3.png'),
              url: '555555',
              name: '红杉中国发布《2023企业数字化指南》',
              tip: '555555',
            },
            {
              id: 6,
              img: require('../../assets/img/news4.png'),
              url: '66666666',
              name: '红杉播客',
            },
            {
              id: 6,
              img: require('../../assets/img/news4.png'),
              url: '66666666',
              name: '红杉播客',
            },
          ],
        },
      ],
    };
  },
};
</script>

<style scoped lang="less">
@import url('./index-center.less');
</style>
