import { createRouter, createWebHistory } from 'vue-router';
import Index from './app/index';
import Team from './app/team';
import Trends from './app/trends';
import Enterprise from './app/enterprise';
import About from './app/about';
import Contact from './app/contact';

// 创建路由规则
const routes = [
  { path: '/', component: Index },
  { path: '/team', component: Team },
  { path: '/trends', component: Trends },
  { path: '/enterprise', component: Enterprise },
  { path: '/about', component: About },
  { path: '/contact', component: Contact },
];

// 创建router实例
const router = createRouter({
  mode: 'hash',
  history: createWebHistory(),
  routes,
});

export default router;
