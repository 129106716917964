<template>
  <div class="search">
    <div class="close" @click="searchClose">×</div>
    <div class="title">搜索红杉</div>
    <div class="search-box">
      <input />
      <img src="../../assets/search.png" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    searchClose: {
      typeof: Function,
      default: null,
    },
  },
  // created() {
  //   // document.getElementsByTagName('html')[0].style.overflow = 'hidden';
  // },
  // beforeDestroy() {
  //   document.getElementsByTagName('html')[0].style.overflow = 'auto';
  // },
};
</script>

<style lang="less" scoped>
.search {
  position: fixed;
  width: 100%;
  min-height: 100%;
  background: url('../../assets/team-bg.png') no-repeat 0 0 / 100% 100%;
  z-index: 999;
  overflow: auto;

  .close {
    position: absolute;
    right: 30px;
    top: 0;
    opacity: 0.6;
    color: white;
    font-size: 70px;
    font-weight: 100;
    cursor: pointer;
  }

  .title {
    color: var(--Colors-Basic-Color-3, #fff);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    /* Display/01 */
    font-family: 'Noto Sans S Chinese';
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    line-height: 78px; /* 108.333% */
    letter-spacing: -3px;
    margin-top: 280px;
    opacity: 0.4;
  }

  .search-box {
    position: relative;
    width: 936px;
    height: 105px;
    border-radius: 25px;
    background: #d9d9d9;
    margin: 16px auto;

    input {
      position: absolute;
      left: 19px;
      top: 13px;
      width: 817px;
      height: 79px;
      line-height: 79px;
      border-radius: 20px;
      background: #205b35;
      border: none;
      outline: none;
      color: white;
      font-size: 50px;
    }

    img {
      position: absolute;
      width: 53px;
      height: 53px;
      right: 26px;
      top: 26px;
      cursor: pointer;
    }
  }
}
</style>
