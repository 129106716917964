<template>
  <div class="index-header">
    <headerBox />
    <div class="index-header-title">
      红杉中国致力于发掘和培养产业界的「参天大树」
      <div class="index-header-tip" :class="{ h5Style: $store.state.isH5 }">
        HongShan is committed to discovering and cultivating towering trees in
        the industry
      </div>
    </div>
    <headerBannerVue />
  </div>
</template>

<script>
import headerBannerVue from '@/components/headerBanner/header-banner.vue';
import headerBox from '@/components/header/header';

export default {
  components: {
    headerBox,
    headerBannerVue,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="less">
@import url('./index-header.less');
</style>
