<template>
  <div class="contact">
    <div class="contact-title">
      <headerBox :inner="true" />
      <div class="content">
        <div class="left">
          <div :class="{ h5Style: $store.state.isH5 }">Hello,</div>
          <div>
            <span :class="{ h5Style: $store.state.isH5 }">
              如有融资计划，请将您的信件和商业计划书发送至 bpchina@hongshan.com
              我们会悉心阅读并尽快予以回复。
            </span>
          </div>
        </div>
        <div class="right" :class="{ h5Style: $store.state.isH5 }">
          <div class="right-content right-content-title">
            <div class="right-name">办公室</div>
            <div class="right-email">联系我们</div>
          </div>
          <div class="right-content" v-for="(item, index) of list" :key="index">
            <div class="right-name">{{ item.name }}</div>
            <div class="right-email">{{ item.mail }}</div>
            <div class="right-address">
              {{ item.address }}
            </div>
            <div class="right-phone">
              <span>{{ item.phone }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btn" :class="{ h5Style: $store.state.isH5 }">
      <div class="btn-left">
        <span v-for="item of btnList" :key="item.id">{{ item.name }}</span>
      </div>
      <div class="btn-right">- 关注红杉</div>
    </div>
    <indexBottom :inner="true" />
  </div>
</template>

<script>
import headerBox from '@/components/header/header';
import indexBottom from '@/app/indexBottom/index-bottom';
export default {
  components: {
    headerBox,
    indexBottom,
  },
  data() {
    return {
      list: [
        {
          name: '北京',
          mail: 'bpchina@hongshan.com',
          address: '北京朝阳区 建国路 77 号华贸中心 3 号写字楼 3606',
          phone: '+86 10 6561 5668',
        },
        {
          name: '上海',
          mail: '',
          address: '南京西路 1366 号恒隆广场 2 号楼 3006',
          phone: '+86 21 6288 4222',
        },
        {
          name: '香港',
          mail: '',
          address: '金钟道 88 号太古广场 2 座 3613 室',
          phone: '+852 2501 8989',
        },
        {
          name: '深圳',
          mail: '',
          address: '南山区粤海街道深南大道9668号华润置地大厦C座4501',
          phone: '+86 755 23608046',
        },
      ],
      btnList: [
        {
          id: 1,
          name: '微信视频号',
          url: '',
        },
        {
          id: 2,
          name: '微信公众号',
          url: '',
        },
        {
          id: 3,
          name: '微博',
          url: '',
        },
        {
          id: 4,
          name: '播客',
          url: '',
        },
        {
          id: 5,
          name: '请扫码关注“红杉汇”',
          url: '',
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.contact {
  background: #014132;
  overflow: hidden;

  .contact-title {
    background: url('../assets/team-bg.png') no-repeat 0 0/100%;
  }

  .title {
    color: var(--Colors-Basic-Color-3, #fff);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    /* Display/01 */
    font-family: 'Noto Sans S Chinese';
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    line-height: 78px; /* 108.333% */
    letter-spacing: -3px;
    padding: 104px 0 70px 0;
  }

  .content {
    padding: 110px 0 63px 250px;
    display: flex;

    .left {
      width: 300px;

      div {
        color: var(--Colors-Basic-Color-3, #fff);
        font-feature-settings: 'clig' off, 'liga' off;
        /* Headline/02 */
        font-family: 'Noto Sans S Chinese';
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px; /* 141.667% */
        letter-spacing: -0.8px;
      }

      div:first-child {
        font-size: 16px;
        margin-bottom: 24px;
      }
    }

    .right {
      width: 500px;
      padding: 0 0 0 130px;
      color: var(--Colors-Basic-Color-3, #fff);
      font-feature-settings: 'clig' off, 'liga' off;
      /* Headline/05 */
      font-family: 'DM Sans';
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px; /* 162.5% */
      letter-spacing: -0.356px;

      .right-content {
        display: flex;
        flex-flow: row wrap;

        .right-name {
          width: 150px;
          margin-right: 30px;
          margin-bottom: 8px;
        }

        .right-email {
          width: 200px;
        }

        .right-address {
          width: 150px;
          margin-right: 30px;
          font-weight: 400;
          font-size: 14px;
        }

        .right-phone {
          width: 200px;
          font-weight: 400;
          display: flex;
          flex-direction: column;
          font-size: 14px;

          span {
            margin-top: auto;
          }
        }
      }

      .right-content-title {
        font-size: 14px;
        opacity: 0.5;
        margin-bottom: 24px;
        margin-top: 50px;
      }
    }
  }

  .btn {
    margin-left: 91px;
    display: flex;

    .btn-left {
      width: 320px;

      span {
        margin-right: 30px;
        color: var(--Colors-Basic-Color-3, #fff);
        font-feature-settings: 'clig' off, 'liga' off;
        /* Headline/06 */
        font-family: 'Noto Sans S Chinese';
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 171.429% */
        letter-spacing: -0.3px;
        cursor: pointer;
      }

      span:last-child {
        font-weight: 500;
      }
    }

    .btn-right {
      margin-left: 76px;
      color: var(--Colors-Basic-Color-3, #fff);
      font-feature-settings: 'clig' off, 'liga' off;
      /* Paragraph/03 */
      font-family: 'Noto Sans S Chinese';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      letter-spacing: -0.3px;
      cursor: pointer;
    }
  }
}
</style>
