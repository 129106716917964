<template>
  <div class="trends">
    <div class="trends-title">
      <headerBox :inner="true" />
      <div class="title">你们改变世界</div>
      <enterpriseCenter />
    </div>
    <indexBottom :inner="true" />
  </div>
</template>

<script>
import headerBox from '@/components/header/header';
import indexBottom from '@/app/indexBottom/index-bottom';
import enterpriseCenter from '@/app/enterpriseCenter/enterprise-center';
export default {
  components: {
    headerBox,
    indexBottom,
    enterpriseCenter,
  },
};
</script>

<style lang="less" scoped>
.trends {
  background: #014132;
  overflow: hidden;

  .trends-title {
    background: url('../assets/team-bg.png') no-repeat 0 0/100%;
  }

  .title {
    color: var(--Colors-Basic-Color-3, #fff);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    /* Display/01 */
    font-family: 'Noto Sans S Chinese';
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    line-height: 78px; /* 108.333% */
    letter-spacing: -3px;
    padding: 107px 0 183px 0;
  }
}
</style>
