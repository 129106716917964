<template>
  <div class="news-banner-box">
    <swiper
      class="news-banner"
      ref="swiper"
      :loop="true"
      :slides-per-view="3"
      :space-between="20"
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide
        class="news-banner-list"
        v-for="item in list"
        :key="item.id"
      >
        <div v-if="!item.children" class="news-banner-card">
          <img :src="item.img" />
          <div class="news-banner-text">{{ item.name }}</div>
        </div>
        <div
          v-else
          class="news-banner-B"
          :class="{ 'news-banner-C': item.three }"
        >
          <div
            class="news-banner-B-card"
            v-for="itemS of item.children"
            :key="itemS.id"
          >
            <img :src="itemS.img" />
            <div
              class="news-banner-text"
              :class="{ h5Style: $store.state.isH5 }"
            >
              {{ itemS.name }}
            </div>
          </div>
        </div>
      </swiper-slide>
      <div class="left" @click="addPage(-1)">
        <img src="../../assets/left.png" />
      </div>
    </swiper>
    <div class="right" @click="addPage(1)">
      <div><img src="../../assets/right.png" />查看更多</div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    bannerList: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.list = this.bannerList;
    });
  },
  setup() {
    let useSwiper = null;

    const onSwiper = (swiper) => {
      useSwiper = swiper;
    };
    const onSlideChange = () => {
      console.log('slide change');
    };

    const addPage = (num) => {
      if (num > 0) {
        useSwiper.slideNext();
        useSwiper.slideNext();
      } else if (num < 0) {
        useSwiper.slidePrev();
      }
    };
    return {
      onSwiper,
      onSlideChange,
      addPage,
    };
  },
};
</script>

<style scoped lang="less">
@import url('./news-banner.less');
</style>
