<template>
  <div class="about">
    <div class="about-title">
      <headerBox :inner="true" />
      <div class="title">红杉中国</div>
      <div class="content">
        <span :class="{ h5Style: $store.state.isH5 }">
          红杉中国是专注于投资科技、医疗健康、消费三大领域的私募股权投资机构。<br /><br />
          自2005年成立以来，红杉中国致力于发掘和培育创新和创业力量，在海内外投资了逾1500家企业，其中涌现出许多具有鲜明技术特征、创新商业模式、具备高成长性和高发展潜力的公司。<br /><br />
          迄今为止，红杉中国有超过160家成员企业成功上市，近130家非上市公司已发展成为独角兽。<br /><br />
          如有融资计划，请将您的信件和商业计划书发送至 bpchina@hongshan.com
          我们会悉心阅读并尽快予以回复。
        </span>
      </div>
    </div>
    <div class="btn" :class="{ h5Style: $store.state.isH5 }">
      <div class="btn-left">
        <span v-for="item of btnList" :key="item.id">{{ item.name }}</span>
      </div>
      <div class="btn-right">- 关注红杉</div>
    </div>
    <indexBottom :inner="true" />
  </div>
</template>

<script>
import headerBox from '@/components/header/header';
import indexBottom from '@/app/indexBottom/index-bottom';
export default {
  components: {
    headerBox,
    indexBottom,
  },
  data() {
    return {
      btnList: [
        {
          id: 1,
          name: '微信视频号',
          url: '',
        },
        {
          id: 2,
          name: '微信公众号',
          url: '',
        },
        {
          id: 3,
          name: '微博',
          url: '',
        },
        {
          id: 4,
          name: '播客',
          url: '',
        },
        {
          id: 5,
          name: '请扫码关注“红杉汇”',
          url: '',
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.about {
  background: #014132;
  overflow: hidden;

  .about-title {
    background: url('../assets/team-bg.png') no-repeat 0 0/100%;
  }

  .title {
    color: var(--Colors-Basic-Color-3, #fff);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    /* Display/01 */
    font-family: 'Noto Sans S Chinese';
    font-size: 72px;
    font-style: normal;
    font-weight: 700;
    line-height: 78px; /* 108.333% */
    letter-spacing: -3px;
    padding: 104px 0 70px 0;
  }

  .content {
    padding: 0 97px;
    color: var(--Colors-Basic-Color-3, #fff);
    font-feature-settings: 'clig' off, 'liga' off;
    /* Headline/02 */
    font-family: 'Noto Sans S Chinese';
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px; /* 141.667% */
    letter-spacing: -0.8px;
    margin-bottom: 159px;
  }

  .btn {
    margin-left: 91px;
    display: flex;

    .btn-left {
      width: 320px;

      span {
        margin-right: 30px;
        color: var(--Colors-Basic-Color-3, #fff);
        font-feature-settings: 'clig' off, 'liga' off;
        /* Headline/06 */
        font-family: 'Noto Sans S Chinese';
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 171.429% */
        letter-spacing: -0.3px;
        cursor: pointer;
      }

      span:last-child {
        font-weight: 500;
      }
    }

    .btn-right {
      margin-left: 76px;
      color: var(--Colors-Basic-Color-3, #fff);
      font-feature-settings: 'clig' off, 'liga' off;
      /* Paragraph/03 */
      font-family: 'Noto Sans S Chinese';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 171.429% */
      letter-spacing: -0.3px;
      cursor: pointer;
    }
  }
}
</style>
