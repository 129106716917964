<template>
  <div class="header-banner-box">
    <div class="header-banner-component">
      <div
        :class="list.length > 3 ? 'header-banner-more' : 'header-banner'"
        v-if="bannerShow"
      >
        <div
          ref="headerBanner"
          class="header-banner-list"
          v-for="item in list"
          :key="item.id"
        >
          <img class="header-banner-card" :src="item.img" />
          <div class="header-banner-text">
            <span :class="{ h5Style: $store.state.isH5 }"
              >{{ item.name }}<br />{{ item.tip }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="left" @click="addPage('left')">
      <img src="../../assets/left-white.png" />
    </div>
    <div class="right" @click="addPage('right')">
      <img src="../../assets/right-white.png" />
    </div>
  </div>
</template>

<script>
import 'swiper/css';
export default {
  data() {
    return {
      list: [
        {
          id: 1,
          img: require('../../assets/img/head1.png'),
          url: '11111',
          name: '对碳宣战！',
          tip: '这个全球新联盟将对气候变化“出实招”',
        },
        {
          id: 2,
          img: require('../../assets/img/head2.png'),
          url: '222222222222222222222222222222222222222222222222222222',
          name: '沈南鹏对话霍华德·舒尔茨',
          tip: '',
        },
        {
          id: 3,
          img: require('../../assets/img/head3.png'),
          url: '33333',
          name: '点击报名 | ',
          tip: '红杉中国创业加速器YUÈ',
        },
        // {
        //   id: 4,
        //   img: require('../../assets/img/head1.png'),
        //   url: '4444444',
        //   name: '4444444',
        //   tip: '4444444',
        // },
        // {
        //   id: 5,
        //   img: require('../../assets/img/head1.png'),
        //   url: '555555',
        //   name: '555555',
        //   tip: '555555',
        // },
      ],
      bannerShow: true, //刷新dom
      btnFlg: true, //防抖
    };
  },
  created() {
    if (this.list.length > 3) {
      this.list.unshift(this.list[this.list.length - 1]);
    }
  },
  methods: {
    addPage(type) {
      if (!this.btnFlg) return;
      this.btnFlg = false;
      const list = this.$refs.headerBanner;
      const num = list.length > 3 ? 1 : 0;
      switch (type) {
        case 'right':
          list.forEach((item, i) => {
            if (i === list.length - 1) {
              item.className += ` right-animation-last`;
            } else if (i === 0 + num) {
              item.className += ` right-animation-big`;
            } else if (i === 1 + num) {
              item.className += ` right-animation-small`;
            } else {
              item.className += ` right-animation-other`;
            }
          });
          break;
        case 'left':
          list.forEach((item, i) => {
            if (i === 0) {
              item.className +=
                list.length > 3
                  ? ' left-animation-last-2'
                  : ` left-animation-last`;
            } else if (i === 2 + num) {
              item.className += ` left-animation-big`;
            } else if (i === 1 + num) {
              item.className += ` left-animation-small`;
            } else {
              item.className += ` left-animation-other`;
            }
          });
          break;
      }

      setTimeout(() => {
        switch (type) {
          case 'right':
            this.list.unshift(this.list[this.list.length - 1]);
            this.list.pop(this.list);
            break;
          case 'left':
            this.list.push(this.list[0]);
            this.list.shift(0, 1);
            break;
        }
        list.forEach((item) => {
          item.className = 'header-banner-list';
        });
        this.bannerShow = false;
        this.$nextTick(() => {
          this.bannerShow = true;
          this.btnFlg = true;
        });
      }, 1000);
    },
  },
};
</script>

<style scoped lang="less">
@import url('./header-banner.less');
</style>
